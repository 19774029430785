<script setup lang="ts">

</script>

<template>
<v-app>
    <v-main>
        <v-container>
            <slot></slot>
        </v-container>
    </v-main>
</v-app>
</template>

<style scoped>

</style>
