<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { useAuthStore } from "@/Stores/Auth";
import { Head, Link, router, usePage } from "@inertiajs/vue3";
import * as Sentry from "@sentry/vue";
import JetApplicationMark from "@/Components/ApplicationMark.vue";
import JetBanner from "@/Components/Banner.vue";
import MainNav from "@/Layouts/MainNav.vue";
import AccountMenu from "@/Layouts/AccountMenu.vue";
import TenantMenu from "@/Layouts/TenantMenu.vue";

const page = usePage();
const auth = useAuthStore();

const title = ref("");

const mainNav = ref(null);

const props = defineProps({
    mainMaxWidth: {
        default: null,
        required: false
    }
});


onBeforeMount(() => {
    if (page.props.auth?.user) {
        auth.user = page.props.auth.user;
    }

    if (page.props.tenant) {
        auth.tenant = page.props.tenant;
    }

    if (page.props.tenants?.length > 0) {
        auth.tenants = page.props.tenants;
    }

    if (page.props.company) {
        auth.company = page.props.company;
    }

    Sentry.setUser({ email: page.props.auth.user.email });
});


const logout = () => {
    router.post(route("logout"));
};


const toggleMainNav = () => {
    mainNav.value.toggle();
};
</script>
<template>
    <Head :title="title" />

    <jet-banner />
    <v-app>

        <v-app-bar
            app
            class="v-bar--underline"
            flat
        >
            <v-app-bar-nav-icon
                v-if="auth.inTenantScope"
                variant="text"
                @click.stop="toggleMainNav()"
            >
            </v-app-bar-nav-icon>


            <Link :href="route('dashboard')" v-if="auth.inTenantScope" class="ml-2">
                <jet-application-mark class="d-block" height="40px" />
            </Link>

            <a :href="route('dashboard')" v-if="!auth.inTenantScope" class="ml-2">
                <jet-application-mark class="d-block" height="40px" />
            </a>

            <v-btn :href="route('dashboard')" v-if="!auth.inTenantScope" class="ml-2" variant="outlined">
                <v-icon start="">mdi-chevron-left</v-icon>
                Back to JGID
            </v-btn>

            <v-toolbar-title class="font-weight-bold">JGID</v-toolbar-title>

            <v-spacer></v-spacer>

            <TenantMenu />

            <AccountMenu />
        </v-app-bar>

        <MainNav ref="mainNav" />

        <v-main>
            <v-container :style="mainMaxWidth ? `max-width:${mainMaxWidth}` : null ">
                <slot></slot>
            </v-container>
        </v-main>


        <!--<app-announcements></app-announcements>-->

    </v-app>

</template>
