import "vuetify/styles";

import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import * as labsComponents from "vuetify/labs/components";

// import LuxonAdapter from "@date-io/luxon"

// const luxon = new LuxonAdapter({locale: localStorage.getItem('locale') ?? 'en'})

let storedTheme = localStorage.getItem("theme");
const defaultTheme = (storedTheme === "light" || storedTheme === "dark") ? storedTheme : (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");
export default createVuetify({
    components: {
        ...components,
        ...labsComponents
    },
    directives,
    theme: {
        defaultTheme: 'light',
        themes: {
            light: {
                colors: {
                    background: "#FFFFFF",
                    surface: "#FFFFFF",
                    primary: "#446ca2",
                    accent: "#fcfcfc",
                    secondary: "#03DAC6",
                    error: "#B00020",
                    info: "#2196F3",
                    success: "#4CAF50",
                    warning: "#FB8C00"
                }
            },
            dark: {
                colors: {
                    primary: "#446ca2"
                }
            }
        }
    }
});
