<script setup>

import {computed, ref} from "vue";
import {usePage} from "@inertiajs/vue3";
import {useAuthStore} from "@/Stores/Auth";

const auth = useAuthStore();

const search = ref('')

const filteredTenants = computed(() => {
    return search.value ? auth.tenants.filter(tenant => {
        return tenant.name.toLowerCase().includes(search.value.toLowerCase())
    }) : auth.tenants
})
</script>

<template>
    <v-menu
        bottom
        offset-y
        rounded
        :close-on-content-click="false"
        v-if="auth.tenant"
    >
        <template v-slot:activator="{ props }">
            <v-btn
                icon
                x-large
                type="button"
                v-bind="props"
            >
                <v-avatar
                    variant="elevated"
                    size="40"
                >
                    <v-img :src="auth.tenant.profile_photo_url" cover></v-img>
                </v-avatar>
            </v-btn>
        </template>


        <v-card class="pt-5">
            <v-list-item-title class="justify-center">
                <div class="mx-auto text-center pb-5" style="min-width: 300px">
                    <v-avatar size="80" variant="elevated"
                              color="primary darken-1"
                    >
                        <v-img :src="auth.tenant.profile_photo_url" cover></v-img>
                    </v-avatar>
                    <h3 class="mt-3">{{ auth.tenant.name }}</h3>
                    <p class="text-caption mt-1 mb-0">
                        {{ auth.company.name }}
                    </p>


                    <v-btn @click="$inertia.get(route('profile.show'))" rounded variant="outlined"
                           class="mt-1" small
                    >
                        <v-icon start>mdi-domain</v-icon>
                        Manage Company
                    </v-btn>

                </div>
            </v-list-item-title>

            <v-text-field v-model="search" hide-details density="comfortable" placeholder="Search JGID's" :clearable="true" ></v-text-field>
            <v-list  style="max-height: 50vh">
                <v-list-item
                    v-for="tenant in filteredTenants"
                    :href="'//'+tenant.domain"
                    :prepend-avatar="tenant.profile_photo_url"
                    :key="tenant.id"
                    :title="tenant.name"
                >
                </v-list-item>
            </v-list>

        </v-card>

    </v-menu>
</template>
