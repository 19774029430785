import "./bootstrap";
import "../css/app.css";

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";

import { ZiggyVue } from "ziggy-js";

import * as Sentry from "@sentry/vue";

import vuetify from "./Plugins/vuetify";


import AppLayout from "./Layouts/AppLayout.vue";


import { createPinia } from "pinia";

import { i18nVue } from "laravel-vue-i18n";
import AppGuestLayout from "@/Layouts/AppGuestLayout.vue";
import VuetifyInertiaLink from "vuetify-inertia-link";


const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'JGID';

let vueApp;

console.log("Loading app...");

createInertiaApp({
    title: (title) => `${title} - ${appName}`,

    resolve: async name => {
        // const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })

        //import all app views
        // console.log(import.meta.glob('./../../appTenant/Apps/**/Resources/assets/js/Pages/**/*.vue', { eager: true }))


        // let page = pages[`./Pages/${name}.vue`]
        // page.default.layout = name.startsWith('Auth/') ? AppGuestLayout : AppLayout


        const [page, app] = name.split("::");

        const pagePath = app
            ? `../../appTenant/Apps/${app}/Resources/assets/js/Pages/${page}.vue`
            : `./Pages/${page}.vue`;

        const pages = app
            ? import.meta.glob("../../appTenant/Apps/**/Resources/assets/js/Pages/*.vue")
            : import.meta.glob("./Pages/**/*.vue");

        if (!pages[pagePath]) {
            const errorMessage = `Page not found: ${pagePath}`;
            console.log(errorMessage);
            throw new Error(errorMessage);
        }

        // Check if the value is a function (a dynamic import) and call it if so, otherwise use the value directly.
        const resolvedPagePromise = typeof pages[pagePath] === "function" ? await pages[pagePath]() : await pages[pagePath];

        // Await the promise to get the resolved module.
        const resolvedPage = await resolvedPagePromise;

        console.log("resolvedPage", resolvedPage);

        resolvedPage.default.layout = resolvedPage.default.layout ? resolvedPage.default.layout : name.startsWith("Auth/") ? AppGuestLayout : AppLayout;

        return resolvedPage;
    },


    setup({el, App, props, plugin}) {

        const pinia = createPinia()

        vueApp = createApp({render: () => h(App, props)})
            .use(plugin)
            .use(ZiggyVue, window.Ziggy)
            .use(vuetify)
            .use(VuetifyInertiaLink)
            .use(pinia)
            .use(i18nVue, {
                resolve: async lang => {
                    const langs = import.meta.glob('../../lang/*.json');
                    return await langs[`../../lang/${lang}.json`]();
                }
            })
            .mount(el);


        //SENTRY
        if (window.jgid.env === 'production') {
            Sentry.init({
                dsn: "https://2e4f0b33641ab117cf6132f5972dc0f7@secureinsights.jgidbms.com/3",
                integrations: [
                    new Sentry.BrowserTracing({
                        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                        tracePropagationTargets: ["localhost", /^https:\/\/myjgid\.com/]
                    }),
                    new Sentry.Replay({
                        maskAllText: false,
                        blockAllMedia: false
                    }),
                ],
                // Performance Monitoring
                tracesSampleRate: 0.05, //  Capture 100% of the transactions
                // Session Replay
                replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            });


        }
    },
    progress: {color: '#446ca2', includeCSS: true}
}).then();



