import { defineStore } from "pinia";

export const useMainNavStore = defineStore({
    id: "mainNav",
    state: () => ({
        defaultItems: [
            [
                { title: "Dashboard", icon: "mdi-view-dashboard", route: "dashboard" },
                {
                    title: "JGID", icon: "mdi-home", route: "workflow.enquiries.index", items: [
                        [
                            { title: "Enquiries", icon: "mdi-phone", route: "workflow.enquiries.index" },
                            { title: "Opportunities", icon: "mdi-inbox", route: "workflow.enquiries.index" },
                            { title: "Archive", icon: "mdi-archive", route: "workflow.enquiries.index" }
                        ], [
                            { title: "Quotes", icon: "mdi-file-document", route: "workflow.enquiries.index" },
                            {
                                title: "Jobs",
                                icon: "mdi-order-bool-ascending-variant",
                                route: "workflow.enquiries.index"
                            },
                            { title: "Orders", icon: "mdi-truck-delivery", route: "workflow.enquiries.index" }
                        ], [
                            { title: "Invoice", icon: "mdi-cash-multiple", route: "workflow.enquiries.index" }
                        ]
                    ]
                },
                {
                    title: "CRM", icon: "mdi-account-group", route: "crm", items: [
                        [
                            { title: "Clients", icon: "mdi-account", route: "workflow.enquiries.index" },
                            { title: "Suppliers", icon: "mdi-truck-delivery", route: "workflow.enquiries.index" },
                            { title: "Contacts", icon: "mdi-contacts", route: "workflow.enquiries.index" },
                            { title: "Locations", icon: "mdi-home-map-marker", route: "workflow.enquiries.index" }
                        ]
                    ]
                },
                {
                    title: "Inventory", icon: "mdi-warehouse", route: "crm", items: [
                        [
                            { title: "Services", icon: "mdi-wrench", route: "workflow.enquiries.index" }
                        ]
                    ]
                },
                { title: "Tasks", icon: "mdi-checkbox-marked-outline", route: "workflow.enquiries.index" },
                { title: "Calendars", icon: "mdi-calendar", route: "workflow.enquiries.index" },
                { title: "Timesheets", icon: "mdi-clock", route: "workflow.enquiries.index" },
                { title: "Reporting", icon: "mdi-chart-line", route: "workflow.enquiries.index" }

            ]
        ],

        customItems: [],
        customTitle: ""
    }),
    getters: {
        title: (state) => state.customTitle ? state.customTitle : null,
        items: (state) => state.customItems.length ? state.customItems : state.defaultItems
    },
    actions: {
        restoreDefault() {
            this.customTitle = "";
            this.customItems = [];
        }
    }
});
