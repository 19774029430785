import {defineStore} from 'pinia'

export const useAuthStore = defineStore('auth', {
    state: () => ({
        tenant: null,
        tenants: [],
        company: null,
    }),

    getters: {
        inTenantScope: (state) => {
            return state.tenant !== null
        }
    }

})
