<template>
    <v-navigation-drawer
        fixed
        app
        v-model="navigationDrawer"
        :permanent="$vuetify.display.mdAndUp"
        :rail="$vuetify.display.mdAndUp && !navigationDrawer"
        class="select-none"
        :width="300"
        style="border-width: 0"
        v-if="auth.tenant"
    >
        <v-list
            v-for="(group, index) in store.items"
            :key="index"
            class="pl-0 mb-n4"
        >
            <v-list-subheader v-if="store.title">{{ store.title }}</v-list-subheader>

            <v-divider
                v-if="group?.length && index>0"
                class="mb-2"
                :class="{ 'mt-0': !index, 'mt-2': index }"
            />


            <template v-for="item in group">
                <!-- GROUP ITEM -->
                <v-list-group
                    v-if="item && item.items"
                    :key="item.title"
                    :prepend-icon="item.icon"
                    no-action
                >
                    <!-- GROUP TITLE -->
                    <template #activator="{ props }">
                        <v-list-item
                            rounded="e-xl"
                            v-bind="props"
                        >
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                    </template>


                    <div v-for="(subItemGroup,subItemGroupIndex) in item.items">
                        <v-divider
                            v-if="subItemGroup.length && subItemGroupIndex>0"
                            :class="{ 'mt-0': !subItemGroupIndex, 'mt-2': subItemGroupIndex }"
                            class="mb-2"
                        />

                        <v-list-item
                            v-for="subItem in subItemGroup"
                            :active="subItem.route ? route().current(subItem.route) : false"
                            :href="subItem.href?subItem.href:null"
                            :to="subItem.route?route(subItem.route):null"
                            ripple
                            rounded="e-xl"
                            target="_blank"

                            @click="clickItem(subItem)"
                        >
                            <template #prepend>
                                <v-icon :icon="subItem.icon" />
                            </template>

                            <v-list-item-title v-text="subItem.title" />
                        </v-list-item>
                    </div>
                </v-list-group>


                <!--                SINGLE ITEM -->
                <v-list-item
                    v-else-if="item"
                    :href="item.href?item.href:null"
                    :to="route(item.route)"
                    :active="item.route ? route().current(item.route) : false"
                    rounded="e-xl"
                    :disabled="item.disabled ?? false"
                >
                    <template #prepend>
                        <v-icon :icon="item.icon" />
                    </template>

                    <v-list-item-title v-text="item.title" />
                </v-list-item>
            </template>
        </v-list>
    </v-navigation-drawer>

    <v-list>
        <v-list-item :to="route('dashboard')">
            <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item>
    </v-list>


</template>
<script setup>

import { computed, ref } from "vue";
import { useAuthStore } from "@/Stores/Auth";
import { useMainNavStore } from "@/Stores/MainNav.js";

const navigationDrawer = ref(true);

const store = useMainNavStore();


const auth = useAuthStore();

function toggle() {
    navigationDrawer.value = !navigationDrawer.value;
}

const clickItem = function(item) {
    // if (item.route && this.$vuetify.display.smAndDown) {
    //     toggle();
    // }
};

defineExpose({
    toggle
});

</script>
