<template>
    <v-menu
        bottom
        offset-y
        rounded
    >
        <template v-slot:activator="{ props }">
            <v-btn
                icon
                x-large
                type="button"
                v-bind="props"
            >
                <v-avatar
                    variant="elevated"
                    size="40"
                >
                    <v-img :src="$page.props.auth.user.profile_photo_url" cover></v-img>
                </v-avatar>
            </v-btn>
        </template>


        <v-card class="pt-5">
            <v-list-item-title class="justify-center">
                <div class="mx-auto text-center pb-5" style="min-width: 300px">
                    <v-avatar size="80" variant="elevated"
                              color="primary darken-1"
                    >
                        <v-img :src="$page.props.auth.user.profile_photo_url" cover></v-img>
                    </v-avatar>
                    <h3 class="mt-3">{{ $page.props.auth.user.name }}</h3>
                    <p class="text-caption mt-1 mb-0">
                        {{ $page.props.auth.user.email }}
                    </p>


                    <v-btn :href="siteUrl+route('profile.show',null,false)" rounded variant="outlined"
                           class="mt-1" small
                    >
                        <v-icon start>mdi-account</v-icon>
                        Manage Account
                    </v-btn>


                </div>
            </v-list-item-title>
            <v-divider></v-divider>


            <!--DEV MENU-->
            <div v-if="$page.props.auth.user.is_dev">
                <v-list class="text-center py-0">
                    <v-list-item
                        href="/admin" target="_blank"
                    >
                        <v-list-item-title>
                            <v-icon start>mdi-shield-crown</v-icon>
                            Admin
                        </v-list-item-title>

                    </v-list-item>
                    <v-list-item
                        href="/telescope" target="_blank"
                    >
                        <v-list-item-title>
                            <v-icon start>mdi-telescope</v-icon>
                            Telescope
                        </v-list-item-title>

                    </v-list-item>

                    <v-list-item
                        href="/horizon" target="_blank"
                    >
                        <v-list-item-title>
                            <v-icon start>mdi-tray-full</v-icon>
                            Horizon
                        </v-list-item-title>

                    </v-list-item>

                    <v-list-item
                        href="/log-viewer" target="_blank"
                    >

                        <v-list-item-title>
                            <v-icon start>mdi-post</v-icon>
                            Logs
                        </v-list-item-title>
                    </v-list-item>


                    <v-list-item
                        href="/pulse" target="_blank"
                    >
                        <v-list-item-title>
                            <v-icon start>mdi-pulse</v-icon>
                            Pulse
                        </v-list-item-title>

                    </v-list-item>


                </v-list>
                <v-divider></v-divider>
            </div>


            <v-list class="text-center py-0">
                <v-list-item
                    v-if="$page.props.jetstream.hasApiFeatures"
                    @click="$inertia.get(route('api-tokens.index'))"
                >
                    <v-list-item-title>
                        <v-icon size="20">mdi-connection</v-icon>
                        API
                    </v-list-item-title>
                </v-list-item>

                <v-list-item
                    :href="route('logout')"
                >
                    <v-list-item-title>
                        <v-icon size="20">mdi-logout</v-icon>
                        Logout
                    </v-list-item-title>
                </v-list-item>
            </v-list>

        </v-card>

    </v-menu>
</template>
<script setup>
    const siteUrl = window.jgid.siteUrl;
</script>
